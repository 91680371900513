import React from 'react';

const IconBento = () => (
  <svg
    width="45"
    height="41"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 45 41"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-folder">
    <title>Bento.me</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.20586 28.6213C1.88336 26.8037 0.722114 25.8949 0.287025 24.8469L0 23.4195V17.5715H1.46429V20.4809C2.10511 19.88 2.9986 19.1807 4.20586 18.2359L15.8173 9.14873C18.1398 7.33112 19.301 6.42232 20.6401 6.08181C21.818 5.7823 23.0868 5.7823 24.2646 6.08181C25.6037 6.42232 26.765 7.33112 29.0875 9.14873L40.6989 18.2359L40.6989 18.236C41.9062 19.1808 42.7997 19.88 43.4405 20.4809V17.5715H44.9048V23.4286C44.9048 23.9073 44.8091 24.386 44.6177 24.8469C44.1826 25.8949 43.0214 26.8037 40.6989 28.6213L29.0875 37.7085L29.0874 37.7085C26.765 39.5261 25.6037 40.4349 24.2646 40.7754C23.0868 41.0749 21.818 41.0749 20.6401 40.7754C19.301 40.4349 18.1398 39.5261 15.8173 37.7085L4.20586 28.6213Z"
      fill="#DBDBDB"
    />
    <path
      d="M4.20588 22.7641C1.88338 20.9465 0.722125 20.0377 0.287036 18.9897C-0.0956788 18.0679 -0.0956788 17.0749 0.287036 16.1531C0.722125 15.1052 1.88338 14.1964 4.20588 12.3787L15.8173 3.29155C18.1398 1.47395 19.301 0.565142 20.6401 0.224637C21.818 -0.074879 23.0868 -0.074879 24.2647 0.224637C25.6037 0.565142 26.765 1.47395 29.0875 3.29156L40.6989 12.3787C43.0214 14.1964 44.1826 15.1052 44.6177 16.1531C45.0004 17.0749 45.0004 18.0679 44.6177 18.9897C44.1826 20.0377 43.0214 20.9465 40.6989 22.7641L29.0875 31.8513C26.765 33.6689 25.6037 34.5777 24.2647 34.9182C23.0868 35.2177 21.818 35.2177 20.6401 34.9182C19.301 34.5777 18.1398 33.6689 15.8173 31.8513L4.20588 22.7641Z"
      fill="white"
    />
    <path
      d="M17.4761 11.5133C16.3148 10.6045 15.7342 10.1501 15.5166 9.62611C15.3253 9.1652 15.3253 8.66872 15.5166 8.20781C15.7342 7.68383 16.3148 7.22942 17.4761 6.32062L19.1348 5.02245C20.2961 4.11364 20.8767 3.65924 21.5462 3.48899C22.1352 3.33923 22.7696 3.33923 23.3585 3.48899C24.0281 3.65924 24.6087 4.11364 25.7699 5.02245L27.4287 6.32062C28.59 7.22942 29.1706 7.68383 29.3881 8.20781C29.5795 8.66872 29.5795 9.1652 29.3881 9.62611C29.1706 10.1501 28.59 10.6045 27.4287 11.5133L25.7699 12.8115C24.6087 13.7203 24.0281 14.1747 23.3585 14.3449C22.7696 14.4947 22.1352 14.4947 21.5462 14.3449C20.8767 14.1747 20.2961 13.7203 19.1348 12.8115L17.4761 11.5133Z"
      fill="#FF8686"
    />
    <path
      d="M28.5345 20.1678C27.3733 19.259 26.7927 18.8046 26.5751 18.2806C26.3838 17.8197 26.3838 17.3232 26.5751 16.8623C26.7927 16.3383 27.3733 15.8839 28.5345 14.9751L30.1933 13.6769C31.3546 12.7681 31.9352 12.3137 32.6047 12.1435C33.1937 11.9937 33.8281 11.9937 34.417 12.1435C35.0865 12.3137 35.6672 12.7681 36.8284 13.6769L38.4872 14.9751C39.6484 15.8839 40.2291 16.3383 40.4466 16.8623C40.638 17.3232 40.638 17.8197 40.4466 18.2806C40.2291 18.8046 39.6484 19.259 38.4872 20.1678L36.8284 21.4659C35.6672 22.3747 35.0865 22.8291 34.417 22.9994C33.8281 23.1492 33.1937 23.1492 32.6047 22.9994C31.9352 22.8291 31.3546 22.3747 30.1933 21.4659L28.5345 20.1678Z"
      fill="#5AFF88"
    />
    <path
      d="M6.41757 20.1678C5.25632 19.259 4.6757 18.8046 4.45815 18.2806C4.2668 17.8197 4.2668 17.3232 4.45815 16.8623C4.6757 16.3383 5.25632 15.8839 6.41757 14.9751L8.07635 13.6769C9.2376 12.7681 9.81822 12.3137 10.4878 12.1435C11.0767 11.9937 11.7111 11.9937 12.3 12.1435C12.9696 12.3137 13.5502 12.7681 14.7114 13.6769L27.4287 23.6296C28.59 24.5384 29.1706 24.9928 29.3881 25.5167C29.5795 25.9777 29.5795 26.4741 29.3881 26.935C29.1706 27.459 28.59 27.9134 27.4287 28.8222L25.7699 30.1204C24.6087 31.0292 24.0281 31.4836 23.3585 31.6539C22.7696 31.8036 22.1352 31.8036 21.5462 31.6539C20.8767 31.4836 20.2961 31.0292 19.1348 30.1204L6.41757 20.1678Z"
      fill="#768CFF"
    />
  </svg>
);
export default IconBento;
