import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(32.000000, 25.000000)">
        <path
          d="M26.0169 12.2727C25.7612 10.1136 24.7243 8.4375 22.9061 7.24432C21.0879 6.05114 18.8578 5.45454 16.2157 5.45454C14.2839 5.45454 12.5936 5.76704 11.1447 6.39204C9.71005 7.01704 8.58789 7.87642 7.77823 8.97017C6.98278 10.0639 6.58505 11.3068 6.58505 12.6989C6.58505 13.8636 6.86204 14.8651 7.41602 15.7031C7.9842 16.527 8.70863 17.2159 9.58931 17.7699C10.47 18.3097 11.3933 18.7571 12.3592 19.1122C13.3251 19.4531 14.2129 19.7301 15.0225 19.9432L19.4544 21.1364C20.5907 21.4347 21.8549 21.8466 23.247 22.3722C24.6532 22.8977 25.9956 23.6151 27.274 24.5241C28.5666 25.419 29.6319 26.5696 30.47 27.9759C31.3081 29.3821 31.7271 31.108 31.7271 33.1534C31.7271 35.5114 31.1092 37.642 29.8734 39.5455C28.6518 41.4489 26.862 42.9616 24.5041 44.0838C22.1603 45.206 19.3123 45.767 15.96 45.767C12.835 45.767 10.1291 45.2628 7.84215 44.2543C5.56942 43.2457 3.77965 41.8395 2.47283 40.0355C1.18022 38.2315 0.448686 36.1364 0.278232 33.75H5.73278C5.87482 35.3977 6.4288 36.7614 7.39471 37.8409C8.37482 38.9062 9.61062 39.7017 11.1021 40.2273C12.6078 40.7386 14.2271 40.9943 15.96 40.9943C17.9771 40.9943 19.7882 40.6676 21.3933 40.0142C22.9984 39.3466 24.2697 38.4233 25.2072 37.2443C26.1447 36.0511 26.6135 34.6591 26.6135 33.0682C26.6135 31.6193 26.2086 30.4403 25.399 29.5312C24.5893 28.6222 23.524 27.8835 22.2029 27.3153C20.8819 26.7472 19.4544 26.25 17.9203 25.8239L12.551 24.2898C9.14187 23.3097 6.443 21.9105 4.45437 20.0923C2.46573 18.2741 1.47141 15.8949 1.47141 12.9545C1.47141 10.5114 2.13192 8.38068 3.45295 6.5625C4.78817 4.73011 6.57795 3.30966 8.82227 2.30114C11.0808 1.27841 13.6021 0.767044 16.3862 0.767044C19.1987 0.767044 21.6987 1.27131 23.8862 2.27983C26.0737 3.27415 27.8066 4.63778 29.085 6.37074C30.3777 8.10369 31.0595 10.071 31.1305 12.2727H26.0169Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
